
<template>
    <div class="StarterDetail">
        <div class="router">
            <div class="center connectBoxW">
                <router-link to="/Starter" tag="a" ><i class="el-icon-arrow-left"></i> Back to LAUNCHPAD</router-link>
            </div>
        </div>
        <div class="banner">
            <div class="center connectBoxW">
                <div class="poolInfo">
                    <div class="infoLeft">
                        <h1>{{tokenSymbol}} {{ poolItem.accessType == 0 ? 'Public' : 'Private' }} Pool</h1>
                        <a class="addressLink" :href="'https://explorer.zksync.io/address/'+starterAddress" target="_block">{{ starterAddress.substring(0, 32)}}... <i class="el-icon-top-right"></i></a>
                        <p class="tip">This is a smart contract address, please do not transfer any token into it.</p>
                        <div class="btns">
                            <!-- <p>Ended</p> -->
                            <button v-if="poolItem.accessType == 1 && poolItem.Progress >= 100 || 
                                poolItem.accessType == 1 && poolItem.surplus<poolItem.minAlloc || 
                                poolItem.accessType == 1 && poolItem.startTime*1000<now || 
                                poolItem.accessType == 0 && poolItem.startTime*1000<now"
                                class="disabled"
                                >Ended</button>
                            <!-- <button v-if="poolItem.accessType == 1 && poolItem.Progress >= 100 || 
                                poolItem.accessType == 1 && poolItem.surplus<poolItem.minAlloc || 
                                poolItem.accessType == 1 && poolItem.startTime*1000<now || 
                                poolItem.accessType == 0 && poolItem.startTime*1000<now"
                                style="border:0;">Ended</button> -->
                            <button :disabled="disabledbtn" class="join  col-lg-6 col-md-6 col-sm-12 col-xs-12"
                                :class="{'disabled':poolItem.minSwapLevel - (poolItem.TotalDeposit/poolItem.ratio)<=0 && poolItem.accessType==1 || 
                                !userAddress || 
                                QuotaOfData==0 && poolItem.accessType==1 || 
                                now >= poolItem.startTime*1000 && poolItem.accessType == 0 || 
                                now <= poolItem.distTime*1000 && poolItem.accessType == 1 || 
                                now <= poolItem.distTime*1000 && poolItem.accessType == 0 || 
                                now >= poolItem.startTime*1000 && poolItem.accessType == 1 ||
                                AllowanceData!=0}" 
                                @click="Approve" :style="{background:(disabledbtn ? '#918DA8' : '')}"
                                v-else-if="AllowanceData<=0 && this.poolItem.offering.isMainType == false"
                                >Enable <i v-if="disabledbtn" class="el-icon-loading"></i></button>
                            <button class="join  col-lg-6 col-md-6 col-sm-12 col-xs-12"
                                :class="{'disabled':poolItem.minSwapLevel - (poolItem.TotalDeposit/poolItem.ratio)<=0 && poolItem.accessType==1  ||
                                !userAddress ||
                                QuotaOfData==0 && poolItem.accessType==1 ||
                                DepositOfData>0 && poolItem.accessType==1 ||
                                now >= poolItem.startTime*1000 && poolItem.accessType == 0 || 
                                now <= poolItem.distTime*1000 && poolItem.accessType == 1 || 
                                now <= poolItem.distTime*1000 && poolItem.accessType == 0 || 
                                now >= poolItem.startTime*1000 && poolItem.accessType == 1}" 
                                @click="joinPool" 
                                v-else 
                                >Join Pool</button>
                            <a :href="'https://explorer.zksync.io/address/'+starterAddress" target="_block">View zkSync</a>
                            <!-- <p>View zkSync</p> -->
                        </div>
                    </div>
                    <div class="infoRight">
                        <p class="swapAmount">
                            <span> <i class="dian"></i>Swap Amount</span>
                            <span>{{ (poolItem.minSwapLevel * poolItem.ratio).toLocaleString() }} {{ poolItem.offering.symbol }}</span>
                            <!-- <span>150,000 ETH</span> -->
                        </p>
                        <div class="rightDiv">
                            <p class="finished">
                                <span><i class="dian"></i>Finished</span>
                                <span>{{ Number(poolItem.Progress).toFixed(2) }}%</span>
                            </p>
                            <p class="swapProgress">
                                <span>Swap Progress</span>
                                <span>{{ poolItem.TotalDeposit }} / {{ (poolItem.minSwapLevel * poolItem.ratio).toLocaleString() }}{{ poolItem.offering.symbol }}</span>
                            </p>
                        </div>
                        <p class="filled">
                            <span class="progress-line">
                                <span class="progress-bar" :style="'width:'+percentage+'%;'"></span>
                            </span>
                        </p>
                        <p class="full" v-if="poolItem.accessType==0">When over raised, it'll allocate tokens by your share in pool and return unspent fund.</p>
                            <p class="full" v-if="poolItem.accessType==1 && poolItem.surplus<poolItem.minAlloc || poolItem.accessType == 1 && poolItem.Progress >= 100 ">Private pool is full!</p>
                        <!-- <p class="full">Private pool is full!</p> -->
                    </div>
                </div>
                <div class="poolAction">
                    <div class="tab">
                        <p :class="actionIndex == 1 ? 'p1' : ''" @click="actionIndex = 1">
                            <span></span> Fundraising
                        </p>
                        <p :class="actionIndex == 2 ? 'p2' : ''" @click="actionIndex = 2">
                            <span></span> Claim
                        </p>
                    </div>
                    <div v-if="actionIndex == 1" class="fundraisingBox">
                        <p>
                            <span>Invested {{ poolItem.offering.symbol }} Amount</span>
                            <span v-if="DepositOfData === '' || DepositOfData == 0">— —</span>
                            <span v-else>{{poolItem.offeringType == 1 ?  DepositOfData.toString().match(/^\d+(?:\.\d{0,3})?/)[0] : DepositOfData.toString().match(/^\d+(?:\.\d{0,3})?/)[0]}}</span>
                            <!-- <span>10</span> -->
                        </p>
                        <p>
                            <span>Expected Lot Winning Rate</span>
                            <span v-if="userAddress == '' || DepositOfData == 0">— —</span>
                            <span v-else>{{( poolItem.accessType == 0 ? HarvestableData.proportion*100 : 100).toFixed(2)}}%</span>
                        </p>
                        <p>
                            <span>Expected Lot Winning Amounts</span>
                            <span v-if="userAddress == '' || DepositOfData == 0">— —</span>
                            <span v-else>{{poolItem.accessType == 0 ? (DepositOfData / poolItem.ratio * HarvestableData.proportion).toString().match(/^\d+(?:\.\d{0,3})?/)[0] : (DepositOfData / poolItem.ratio).toString().match(/^\d+(?:\.\d{0,3})?/)[0]}} </span>
                            <!-- <span>100</span> -->
                        </p>
                    </div>
                    <div v-if="actionIndex == 2" class="claimBox">
                        <p>
                            <span>Unspent {{poolItem.offering.symbol}} Amount</span>
                            <span v-if="poolItem.accessType == 0 && now < poolItem.startTime*1000">— —</span>
                            <span v-else-if="poolItem.accessType == 1 && now < poolItem.startTime*1000">— —</span>
                            <span v-else-if="DepositOfData == 0">— —</span>
                            <span v-else>{{poolItem.accessType == 0 ? poolItem.offeringType == 1 ? HarvestableData.amount.toString().match(/^\d+(?:\.\d{0,3})?/)[0] : HarvestableData.amount.toString().match(/^\d+(?:\.\d{0,3})?/)[0] : 0.00}}</span>
                            <!-- <span>100</span> -->
                        </p>
                        <p>
                            <span>Obtained {{poolItem.claimCoin.symbol}} Amount</span>
                            <span v-if="poolItem.accessType == 0 && now < poolItem.startTime*1000">— —</span>
                            <span v-else-if="poolItem.accessType == 1 && now < poolItem.startTime*1000">— —</span>
                            <span v-else-if="DepositOfData == 0">— —</span>
                            <span v-else>{{poolItem.accessType == 0 ? (DepositOfData / poolItem.ratio * HarvestableData.proportion).toString().match(/^\d+(?:\.\d{0,3})?/)[0] : (DepositOfData / poolItem.ratio).toString().match(/^\d+(?:\.\d{0,3})?/)[0]}}</span>
                            <!-- <span>100</span> -->
                        </p>
                        <p>
                            <span>Claimed</span>
                            <span v-if="poolItem.accessType == 0 && now < poolItem.startTime*1000">— —</span>
                            <span v-else-if="poolItem.accessType == 1 && now < poolItem.startTime*1000">— —</span>
                            <span v-else-if="DepositOfData == 0">— —</span>
                            <span v-else>{{receiveRatio == 0 ? 
                                '0.000' : 
                                    receiveRatio == 10000 ? 
                                        poolItem.accessType == 0 ? 
                                        (DepositOfData / poolItem.ratio * HarvestableData.proportion).toString().match(/^\d+(?:\.\d{0,3})?/)[0] : 
                                        (DepositOfData / poolItem.ratio).toString().match(/^\d+(?:\.\d{0,3})?/)[0] : 
                                            poolItem.accessType == 0 ?
                                            (DepositOfData / poolItem.ratio * HarvestableData.proportion * receiveRatio / 10000).toString().match(/^\d+(?:\.\d{0,3})?/)[0] : 
                                            (DepositOfData / poolItem.ratio * receiveRatio / 10000).toString().match(/^\d+(?:\.\d{0,3})?/)[0]}} </span>
                            <!-- <span>100</span> -->
                        </p>
                        <p>
                            <span>Pending</span>
                            <span v-if="poolItem.accessType == 0 && now < poolItem.startTime*1000">— —</span>
                            <span v-else-if="poolItem.accessType == 1 && now < poolItem.startTime*1000">— —</span>
                            <span v-else-if="DepositOfData == 0">— —</span>
                            <!-- <span v-else>{{poolItem.accessType == 0 ? (DepositOfData / poolItem.ratio * HarvestableData.proportion).toFixed(3) : DepositOfData / poolItem.ratio}} {{poolItem.claimCoin.symbol}}</span> -->
                            <span v-else>{{pendingNum.toString().match(/^\d+(?:\.\d{0,3})?/)[0]}} </span>
                            <!-- <span>100</span> -->
                        </p>
                        <p v-if="DepositOfData == 0"><span class="clainbtn disabled">Claim</span></p>
                        <p v-else-if="now < poolItem.endTime*1000 && settlement  == 0"><span class="clainbtn disabled">Claim</span></p>
                        <p v-else-if="now > poolItem.endTime*1000 && receiveNumberA  == receiveNumberB"><span class="clainbtn disabled">Claimed</span></p>
                        <p v-else-if="!harvestShow"><span class="clainbtn disabled" >Claim <v-LoadingWarp style="margin-left: 5px;" v-if="depositBtnShow" :imgSize="1"></v-LoadingWarp></span></p>
                        <p v-else><span class="clainbtn " @click="handdleHarvestBtn">Claim</span></p>
                        <!-- <p class="clainbtn">
                            Claim
                        </p> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="introduce">
            <div class="introduceTab">
                <div class="center connectBoxW">
                    <div @click="introduceIndex = 1" :class="introduceIndex == 1 ? 'A' : ''">
                        <p>Pool Details</p>
                        <p></p>
                    </div>
                    <div @click="introduceIndex = 2" :class="introduceIndex == 2 ? 'A' : ''">
                        <p>About the Project</p>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="center connectBoxW">
                <div v-if="introduceIndex == 1" class="PoolDetails">
                    <div class="PoolInformation">
                        <p class="title">
                            <span>Pool Information</span>
                            <span></span>    
                        </p>
                        <p>
                            <span>Sale Start Time</span>
                            <span>{{poolItem.distTime | timetranss}} AM UTC</span>    
                        </p>
                        <p>
                            <span>Sale End Time</span>
                            <span>{{poolItem.startTime | timetranss}} AM UTC</span>    
                        </p>
                        <p>
                            <span>Token Distribution</span>
                            <span>{{poolItem.endTime | timetranss}} AM UTC</span>    
                        </p>
                        <p>
                            <span>Single Address (Min-Max)</span>
                            <span>{{poolItem.minAlloc | locaLeString}} {{poolItem.offering.symbol}} - {{poolItem.maxAlloc | locaLeString}} {{poolItem.offering.symbol}}</span>    
                        </p>
                        <p>
                            <span>Hardcap</span>
                            <span>{{poolItem.minSwapLevel*poolItem.ratio | locaLeString}} {{poolItem.offering.symbol}}</span>    
                        </p>
                    </div>
                    <div class="TokenInformation">
                        <p class="title">
                            <span>Token Information</span>
                            <span></span>    
                        </p>
                        <p>
                            <span>Vesting</span>
                            <span>
                                {{poolItem.intervalTimeInfo[0] | timetranssYMD}}-{{poolItem.intervalRatioInfo[0]/100}}%;
                                {{poolItem.intervalTimeInfo[1] | timetranssYMD}}-{{poolItem.intervalRatioInfo[1]/100}}%;
                                {{poolItem.intervalTimeInfo[2] | timetranssYMD}}-{{poolItem.intervalRatioInfo[2]/100}}%
                                {{ poolItem.intervalTimeInfo[3] ? ';' : '.'}}
                            </span>    
                        </p>
                        <p>
                            <span></span>
                            <span v-if="poolItem.intervalTimeInfo[3]">
                                {{poolItem.intervalTimeInfo[3] | timetranssYMD}}-{{poolItem.intervalRatioInfo[3]/100}}%.
                            </span>
                            <span v-else style="opacity:0;">
                                100%
                            </span>    
                        </p>
                        <p>
                            <span>Name</span>
                            <span>{{poolItem.claimCoin.symbol}}</span>    
                        </p>
                        <p>
                            <span>Address</span>
                            <span>{{starterAddress}}</span>    
                        </p>
                        <p>
                            <span>Total Supply</span>
                            <span style="line-height: 30px;" v-html="poolItem.claimCoin.totalSupplyText"></span>    
                        </p>
                    </div>
                </div>
                <div v-if="introduceIndex == 2" class="AbouttheProject">
                    <ul>
                        <li v-for="(item,index) in aboutList" :key="index" @mouseover="item.move = true" @mouseout="item.move = false">
                            <a :href="item.link" target="_blank" rel="noopener noreferrer" :style="{color: item.move ? '#7F4FFD' : '#fff'}">
                                <span><img :src="item.move ? item.logoA : item.logoB" alt=""></span>  {{item.link}}
                            </a> 
                        </li>
                    </ul>
                    <p class="AbouttheProjectText">
                        {{poolItem.claimCoin.profile}} 
                    </p>
                </div>
                
                <v-Footer></v-Footer>
            </div>
        </div>

        <div class="starterMessageBox" v-if="showDialog || tipsShow || joinOneTips">
            <div v-if="showDialog" class="connectBoxW">
                <div class="claimTokensBox warp">
                    <div class="ClaimTokens">
                        <div class="titlebox">
                            <h1>
                                Join Pool
                                <i class="el-icon-close" @click="closebox"></i>
                            </h1>
                            <!-- <div v-if="activeislp == 1" class="textbox"> 
                                The LP withdrawal doesn't need unbonding period
                            </div> -->
                            <div v-if="offeringType == 1" class="textbox">Your balance: {{ERC20Balance}} ETH <br /><span v-if="poolItem.accessType == 1">Investable amount left in pool: {{Number(poolItem.surplus).toFixed(6)}} ETH</span></div><!-- <div>{{BalanceOfData}} {{poolItem.offering.symbol}} Available</div> -->
                            <div v-if="offeringType == 0" class="textbox">Your balance: {{BalanceOfData}} {{poolItem.offering.symbol}}<br /><span v-if="poolItem.accessType == 1">Investable amount left in pool: {{Number(poolItem.surplus).toFixed(6)}} {{poolItem.offering.symbol}}</span></div><!-- <div>{{BalanceOfData}} {{poolItem.offering.symbol}} Available</div> -->
                            <div v-if="offeringType == 2" class="textbox">Your balance: {{BalanceOfData}} {{poolItem.offering.symbol}} / {{ERC20Balance}} ETH <br /><span v-if="poolItem.accessType == 1">Investable amount left in pool: {{Number(poolItem.surplus).toFixed(6)}} {{poolItem.offering.symbol}} / {{Number(poolItem.surplus/Number(ratioBNB)).toFixed(6)}} ETH</span></div><!-- <div>{{BalanceOfData}} {{poolItem.offering.symbol}} Available</div> -->
                        </div>
                        <!-- <h3>Join Pool</h3> -->
                        <!-- <h4 v-if="offeringType == 1">Your balance: {{ERC20Balance}} ETH <br /><span v-if="poolItem.accessType == 1">Investable amount left in pool: {{Number(poolItem.surplus).toFixed(6)}} ETH</span></h4><h4>{{BalanceOfData}} {{poolItem.offering.symbol}} Available</h4> -->
                        <!-- <h4 v-if="offeringType == 0">Your balance: {{BalanceOfData}} {{poolItem.offering.symbol}}<br /><span v-if="poolItem.accessType == 1">Investable amount left in pool: {{Number(poolItem.surplus).toFixed(6)}} {{poolItem.offering.symbol}}</span></h4><h4>{{BalanceOfData}} {{poolItem.offering.symbol}} Available</h4> -->
                        <!-- <h4 v-if="offeringType == 2">Your balance: {{BalanceOfData}} {{poolItem.offering.symbol}} / {{ERC20Balance}} ETH <br /><span v-if="poolItem.accessType == 1">Investable amount left in pool: {{Number(poolItem.surplus).toFixed(6)}} {{poolItem.offering.symbol}} / {{Number(poolItem.surplus/Number(ratioBNB)).toFixed(6)}} ETH</span></h4><h4>{{BalanceOfData}} {{poolItem.offering.symbol}} Available</h4> -->
                        
                        <div class="Pleaseselectthetokenyouwanttopaywith" style="margin-top: 30px;">
                            <p class="smalltitle">Select Payment:</p>
                            <div class="box">
                                <div v-if="offeringType == 0 || offeringType == 2" :class=" Payment == 1 ? 'active' : '' " @click="selectToken(1)"><img src="https://arweave.net/8TojFbaI5NOEoDMlhbBmKwtiKOc9PPiOqxNnR80T4yA" alt=""><span>USDC.e</span></div>
                                <div v-if="offeringType == 1 || offeringType == 2" :class=" Payment == 2 ? 'active' : '' " @click="selectToken(2)"><img src="https://arweave.net/5MbY31PcSQip4Rrvk0OaquQBaGSzPMWirW8U_cIKwZ4" alt=""><span>ETH</span></div>
                            </div>
                        </div>
                        <!-- <p style="margin-bottom:5px;">Select Payment:</p> -->
                        <!-- <ul class="selectToken" v-if="offeringType == 1">
                            <li :class="Payment == 2 ? '' : 'noselect'" @click="selectToken(2)">
                                <span style="display:block;width:25px;height:25px;background:#fff;border-radius: 50%;"><img style="width:21px;height:21px;margin:2px;" src="https://arweave.net/5MbY31PcSQip4Rrvk0OaquQBaGSzPMWirW8U_cIKwZ4" alt=""></span><span>ETH</span>
                            </li>
                        </ul>
                        <ul class="selectToken" v-if="offeringType == 0">
                            <li :class="Payment == 1 ? '' : 'noselect'" @click="selectToken(1)">
                                <span style="display:block;width:25px;height:25px;background:#fff;border-radius: 50%;"><img src="https://arweave.net/8TojFbaI5NOEoDMlhbBmKwtiKOc9PPiOqxNnR80T4yA" alt=""></span><span>USDC</span>
                            </li>
                        </ul>
                        <ul class="selectToken" v-if="offeringType == 2">
                            <li :class="Payment == 1 ? '' : 'noselect'" @click="selectToken(1)">
                                <span style="display:block;width:25px;height:25px;background:#fff;border-radius: 50%;"><img src="https://arweave.net/8TojFbaI5NOEoDMlhbBmKwtiKOc9PPiOqxNnR80T4yA" alt=""></span><span>USDC</span>
                            </li>
                            <li :class="Payment == 2 ? '' : 'noselect'" @click="selectToken(2)">
                                <span style="display:block;width:25px;height:25px;background:#fff;border-radius: 50%;"><img style="width:23px;height:23px;margin:2px;" src="https://arweave.net/5MbY31PcSQip4Rrvk0OaquQBaGSzPMWirW8U_cIKwZ4" alt=""></span><span>ETH</span>
                            </li>
                        </ul> -->
                        <div class="iptbox">
                            <div>
                                <input type="number" name="" id="" v-model="amount" :placeholder="minAmount +'-'+ maxAmount" @keydown="changeNum">
                                <span>{{depositSymbol}}</span>
                            </div>
                            
                            <div class="maxbtn" @click="max">Max</div>
                        </div>
                        <!-- <div class="amountBox">
                            <div class="inputBox">
                                <input type="number" v-model="amount" :placeholder="minAmount +'-'+ maxAmount" @keydown="changeNum">h
                                <span class="md-suffix">{{depositSymbol}}</span>
                            </div>
                            <span class="maxBox" @click="max">Max</span>
                        </div> -->
                        <div v-if="poolItem.accessType == 1" style="font-size:14px;margin-top:20px;margin-bottom:20px;">
                            <i class="el-icon-warning" style="color:#e6a23b;margin-right:10px;"></i>Reminder: in private pool, you can invest only once.
                        </div>
                        <div class="btnbox">
                            <div class="closebtn" @click="closebox">close</div>
                            <div class="confirmbtn" @click="deposit">Confirm <v-LoadingWarp style="margin-left: 5px;" v-if="!depositBtnShow" :imgSize="1"></v-LoadingWarp></div>
                        </div>
                    </div>
                    
                    <!-- <div>
                        <button class="md-primary" @click="closebox">Close</button>
                        <button v-if="depositBtnShow" class="md-primary" @click="deposit()">{{ depositText }}</button>
                        <button v-else class="md-primary" style="background:#918DA8">{{ depositText }} <i class="el-icon-loading"></i></button>
                    </div> -->
                </div>
            </div>
            <div v-if="tipsShow" class="connectBoxW">
                <div class="tipsBox warp">
                    <div class='ClaimTokens'>
                        <h3>Tips</h3>
                        <div style="font-size:16px;">
                            <i class="el-icon-warning" style="color:#e6a23b;margin-right:10px;"></i>You can invest only once in private pool.
                        </div>
                    </div>
                    <div>
                        <button class="md-primary" @click="tipsNoBtn">Cancel</button>
                        <button class="md-primary" @click="tipsOkBtn">OK</button>
                    </div>
                </div>
            </div>
            
            <div v-if="joinOneTips" class="connectBoxW">
                <div class="tipsBox warp">
                    <h1>
                        Tips
                        <i class="el-icon-close" @click="closejoinOneTips"></i>
                    </h1>
                    <div class="sellnft">
                        <p style="padding-bottom:20px;"><i class="el-icon-warning" style="color:#F1E74F;"></i> Reminder: in private pool, you can invest only once.</p>
                        
                    </div>
                    <div class="btnbox">
                        <div class="closebtn" @click="closejoinOneTips">Cancel</div>
                        <!-- <div class="confirmbtn" @click="Unstakingharvesting">OK </div> -->
                    </div>
                    <!-- <div class='ClaimTokens'>
                        <h3>Tips</h3>
                        <div style="font-size:16px;">
                            <i class="el-icon-warning" style="color:#e6a23b;margin-right:10px;"></i>Reminder: in private pool, you can invest only once.
                        </div>
                    </div>
                    <div>
                        <button class="md-primary" @click="closejoinOneTips()">Cancel</button> -->
                        <!-- <button class="md-primary" @click="tipsOkBtn">OK</button> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
        


    </div>
</template>

<script>
import Web3 from "web3";
import $ from "jquery"; 
import {timetrans,timetransYMD,numberToFixed} from "../utils/function.js";
import { getRatioBNB,getReceiveRatio,getReceiveNumber,getIntervalTime,getIntervalNumber,judgeNetwork, getTotalDeposit, getHarvestable, getraiseSupply, getAllowance, getDepositOf, setDeposit, setHarvest, getQuotaOf, getBalanceOf, setApprove, setTledUnderlyingOf} from "../utils/space.js"
export default {
    name: "StarterDetail",
    data() {
        return {
            tokenSymbol:'',xtokenSymbol:'',
            percentage:0,
            percentageOK:0,
            aboutList:[
                {
                    link:'https://www.spacefi.io',
                    logoA:require('../assets/logos/spacefi.png'),
                    logoB:require('../assets/logos/spacefiFFF.png'),
                    move:false,
                },{
                    link:'https://space-finance.medium.com',
                    logoA:require('../assets/logos/mediumA.png'),
                    logoB:require('../assets/logos/mediumB.png'),
                    move:false,
                },{
                    link:'https://twitter.com/spacefi_io',
                    logoA:require('../assets/logos/titwerA.png'),
                    logoB:require('../assets/logos/titwerB.png'),
                    move:false,
                },{
                    link:'https://docs.spacefi.io',
                    logoA:require('../assets/logos/docsA.png'),
                    logoB:require('../assets/logos/docsB.png'),
                    move:false,
                },{
                    link:'https://discord.gg/nezjCa5j8r',
                    logoA:require('../assets/logos/discordA.png'),
                    logoB:require('../assets/logos/discordB.png'),
                    move:false,
                },{
                    link:'https://www.youtube.com/channel/UCg_vqttyVABAfhtfZJOPykg',
                    logoA:require('../assets/logos/YouTubeA.png'),
                    logoB:require('../assets/logos/YouTubeB.png'),
                    move:false,
                }
                
            ],
            actionIndex:1,
            introduceIndex:1,
            ifOpenNew:0,
            offeringType:0,
            Payment:1,
            minAmount:0,maxAmount:0,
            joinOneTips:false,
            harvestShow:true,
            depositText:'',
            depositBtnShow:true,
            tipsShow:false,
            disabledbtn:false,
            show:false,menuType:1,bannerType:1,showDialog:false,amount:'',
            tooltipActive:false,userAddress:'', 
            LanguageIndex:'',infoData:[],starterAddress:'',poolItem:[],showType:true,QuotaOfData:0,AllowanceData:0,BalanceOfData:0,ERC20Balance:0,depositSymbol:'',
            itemNum:3,
            HarvestableData:{proportion:100,volume:100,amount:0},HarvestOfData:'',DepositOfData:0,maxBalanceof:'',
            receiveRatio:0,receiveNumberA:0,receiveNumberB:0,ratioBNB:0,intervalTimeInfoIndex:0,pendingNum:0,
        }
    },
    filters: { 
        timetranss:function(time){
            var _Time = new Date()
            var _TimeDiffer = _Time.getTimezoneOffset() * 60000
            return timetrans( String(time).length == 10 ? (time*1000) + _TimeDiffer : time + _TimeDiffer)
        },
        timetranssYMD:function(time){
            var _Time = new Date()
            var _TimeDiffer = _Time.getTimezoneOffset() * 60000
            return timetransYMD( String(time).length == 10 ? (time*1000) + _TimeDiffer : time + _TimeDiffer)
        },
        locaLeString:function(num){
            return num
        },
        splicedit:function(str,type){
            if (type == 1) {
                return Number(str).toFixed(6)
            }
            return str.split('.')[0]
        },
        changeToFixed:function(){

        }
    },
    async created() {
        document.body.scrollIntoView()
        var that = this;
        this.showType = false;
        this.userAddress = localStorage.getItem('userAddress');
        this.tokenSymbol = localStorage.getItem('tokenSymbol')
        this.xtokenSymbol = localStorage.getItem('xtokenSymbol')
        this.starterAddress = this.$route.query.add
        this.infoData = JSON.parse(JSON.stringify(window.starterData))
        this.now = Date.parse(new Date());
        for(let i of this.infoData){
            if(i.offerContract == this.starterAddress){
                this.poolItem = i;
                //console.log(this.poolItem)
                break
            }
        }
        this.getUserAddr();
        let accessType = '';
        if(this.poolItem.accessType == 0){accessType = 'Public';}
        else if(this.poolItem.accessType == 1){accessType = 'Private';}
        else{accessType = 'UNKONW'}
        this.offeringType = this.poolItem.offeringType
        this.depositSymbol = this.poolItem.offering.symbol
        this.minAmount = this.poolItem.minAlloc
        this.maxAmount = this.poolItem.maxAlloc
        if (this.offeringType == 1) {
            this.Payment = 2
            this.depositSymbol = 'ETH'
        } else if (this.offeringType == 0) {
            this.Payment = 1
            this.depositSymbol = this.poolItem.offering.symbol
        }
        getraiseSupply(this.poolItem.claimCoin.contract).then(raiseRes => {
            this.poolItem.claimCoin.totalSupply = this.$BigNumber(raiseRes).plus(this.poolItem.claimCoin.allStar).div(Math.pow(10,this.poolItem.claimCoin.decimals))
        })
        let decimals = Math.pow(10,this.poolItem.offering.decimals)
        await getTotalDeposit(this.poolItem).then(res => {
            //console.log(res)
            this.$nextTick(() => {
                that.poolItem.endTime = that.poolItem.endTime/1000; 
                that.poolItem.distTime = that.poolItem.distTime/1000;
                that.poolItem.startTime = that.poolItem.startTime/1000;
                that.poolItem.accessTypeText = accessType;
                that.poolItem.TotalDeposit= that.poolItem.accessType == 0 ? this.$BigNumber(res).div(decimals).toString() : (that.poolItem.minSwapLevel * that.poolItem.ratio) - this.$BigNumber(res).div(Math.pow(10,this.poolItem.claimCoin.decimals)).times(that.poolItem.ratio) < that.poolItem.minAlloc ? that.poolItem.minSwapLevel * that.poolItem.ratio : this.$BigNumber(res).div(Math.pow(10,this.poolItem.claimCoin.decimals)).times(that.poolItem.ratio).toString();
                //console.log(that.poolItem.TotalDeposit)
                let a = Number(that.poolItem.minSwapLevel * that.poolItem.ratio)
                let b = Number(that.poolItem.TotalDeposit)
                that.poolItem.surplus = this.$BigNumber(a).minus(b).toString()
                
                that.poolItem.Progress = that.poolItem.accessType == 1 &&  (that.poolItem.TotalDeposit / that.poolItem.ratio / that.poolItem.minSwapLevel)*100 > 99 ? 100 : (that.poolItem.TotalDeposit / that.poolItem.ratio / that.poolItem.minSwapLevel)*100;
                this.percentageOK = that.poolItem.Progress
            });
        },error => {
            // //console.log(error);
        });

        if(this.percentageOK!=0){
            var time = setInterval(() => {
                this.percentage++
                if (Math.ceil(this.percentage) == Math.ceil(this.percentageOK)) {
                    clearInterval(time)
                }
            }, this.percentageOK >= 40 ? 1000/this.percentageOK : 500/this.percentageOK)
        }
        await getBalanceOf(this.poolItem.claimCoin.contract,this.poolItem.offerContract).then(res => {
            this.$nextTick(() => {
                if (res == 0) {
                    that.poolItem.BalanceOfData = res
                } else {
                    var balance = res.slice(0,-this.poolItem.claimCoin.decimals)
                    if (balance.length > 18) {
                        that.poolItem.BalanceOfData = balance.slice(0,18) + '…';
                    } else {
                        that.poolItem.BalanceOfData = balance.slice(0,18);
                    }
                }
            });
        },error => {
            // //console.log(error);
        });
        
        var poolType = false;
        if(this.poolItem.claimCoin.coinType == 1 && this.poolItem.claimCoin.isMainType == false){
            poolType = true
        }
        if(this.userAddress !== '0x0000000000000000000000000000000000000000'){
            if(this.poolItem.offering.isMainType == false && this.poolItem.offering.contract != '0x0000000000000000000000000000000000000000'){
                await getAllowance(this.poolItem.offering.contract ,this.poolItem.offerContract,this.userAddress).then(res => {
                    this.$nextTick(() => {
                        that.AllowanceData = res;
                    });
                },error => {
                    // //console.log(error);
                }).catch( err => {
                    // //console.log(err)
                });
            } else {
                this.AllowanceData = '0x123123123123'
            }
            getRatioBNB(this.poolItem,this.userAddress).then(res => {
                //console.log(res)
                var ratioBNB = this.$BigNumber(res).div(Math.pow(10,this.poolItem.offering.decimals))
                this.ratioBNB = ratioBNB.toString()
                //console.log(this.$BigNumber(this.poolItem.minAlloc).div(ratioBNB))
                // poolItem.minAlloc +'-'+ poolItem.maxAlloc
                this.poolItem.minAllocERC20 = Number(this.$BigNumber(this.poolItem.minAlloc).div(ratioBNB).toString()).toFixed(6)
                this.poolItem.maxAllocERC20 = Number(this.$BigNumber(this.poolItem.maxAlloc).div(ratioBNB).toString()).toFixed(6)
                //console.log(this.poolItem)
            }).catch(error => {
                // //console.log(error)
            })

            var intervalTimeArr = []
            await getIntervalNumber(this.poolItem,this.userAddress).then(IntervalNumber => {
                getReceiveNumber(this.poolItem,this.userAddress).then(resReceiveNumber => {
                    this.receiveNumberB = resReceiveNumber
                })
                for (let i = 0; i < IntervalNumber; i++) {
                    getIntervalTime(this.poolItem,i,this.userAddress).then(IntervalTime => {
                        intervalTimeArr.unshift(IntervalTime)
                    })
                    
                }
            })
            
            await setTledUnderlyingOf(this.poolItem,this.userAddress,poolType).then(res => {
                that.settlement = res/Math.pow(10,18)
            }).catch( err => {
                // //console.log(err)
            })
            if (this.poolItem.accessType == 0) {
                await getHarvestable(this.poolItem,this.userAddress,poolType).then(res => {
                    //console.log(res)
                    this.$nextTick(() => {
                        that.HarvestableData = res;
                        that.HarvestableData.amount = this.$BigNumber(res.amount).div(decimals);
                        that.HarvestableData.volume = this.$BigNumber(res.volume).div(decimals);
                        that.HarvestableData.proportion = this.$BigNumber(res.rate).div(Math.pow(10,this.poolItem.claimCoin.decimals));
                    });
                },error => {
                    // //console.log(error);
                }).catch( err => {
                    // //console.log(err)
                }); 
            }
            if (this.poolItem.accessType == 1) {
                await getQuotaOf(this.poolItem,this.userAddress).then(res => {
                    this.$nextTick(() => {
                        //console.log(res)
                        that.QuotaOfData = res;

                    });
                },error => {
                    // //console.log(error);
                }).catch( err => {
                    // //console.log(err)
                });
            }
            await getDepositOf(this.poolItem,this.userAddress).then(res => {
                this.$nextTick(async () => {
                    //console.log(res)
                    that.DepositOfData = that.poolItem.accessType == 0 ? this.$BigNumber(res).div(Math.pow(10,that.poolItem.offering.decimals)) : this.$BigNumber(res).div(Math.pow(10,this.poolItem.claimCoin.decimals)).times(that.poolItem.ratio);});
                },error => {
                // //console.log(error);
            }).catch( err => {
                // //console.log(err)
            }); 
            getReceiveRatio(this.poolItem,this.userAddress).then(res => {
                this.receiveRatio = res
                if(this.now/1000>this.poolItem.intervalTimeInfo[this.poolItem.intervalTimeInfo.length-1]/1000){
                    this.intervalTimeInfoIndex = this.poolItem.intervalTimeInfo.length
                }else if (this.now/1000<this.poolItem.intervalTimeInfo[0]/1000){
                    this.intervalTimeInfoIndex = 0
                }else{
                    for (let i = 0; i < this.poolItem.intervalTimeInfo.length; i++) {
                        if(this.now/1000>this.poolItem.intervalTimeInfo[i]/1000&&this.now/1000<this.poolItem.intervalTimeInfo[i+1]/1000){
                            this.intervalTimeInfoIndex = i+1
                        }
                    }
                }
                var a = this.poolItem.accessType == 0 ? this.DepositOfData / this.poolItem.ratio * this.HarvestableData.proportion : this.DepositOfData / this.poolItem.ratio
                var b = 0
                if(this.intervalTimeInfoIndex==Number(this.receiveNumberB)){
                    this.pendingNum = 0
                    return false
                }
                if(this.intervalTimeInfoIndex>Number(this.receiveNumberB)){
                    for (let j = 0; j < this.intervalTimeInfoIndex; j++) {
                        b+=this.poolItem.intervalRatioInfo[j]
                    }
                }
                b=b-res
                this.pendingNum = a*b/10000
            }).catch(err => {
            })
            intervalTimeArr.sort((a,b) => {
                if (a > b) { return 1;
                } else if (a< b) { return -1;
                } else { return 0; }
            })
            for (let i = 0; i < intervalTimeArr.length; i++) {
                if(intervalTimeArr[i]<=this.now/1000){
                    var timeIndex = i+1
                }
                
            }
            intervalTimeArr.splice(timeIndex,0,this.now/1000)
            intervalTimeArr.forEach((item,index) => {
                if(item == this.now/1000){
                    this.receiveNumberA =  index
                }
            })
        }
        this.showType = true;
        ethereum.request({
            method: 'eth_getBalance',
            params: [this.userAddress],
        }).then((success) => {
            this.ERC20Balance = Number(this.$BigNumber(Web3.utils.toBN(success)).div(Math.pow(10,18)).toString()).toFixed(6)
        },error=> {
            // //console.log(error)
        }).catch(error => {
            // //console.log(error)
        })
    },
    mounted(){
        this.getUserAddr();
        $('.videoBox').css('height',$(window).height() - 300);
    },
    methods: {
        closebox(){
            this.showDialog = false
            this.amount = ''
        },
        selectToken(a){
            this.Payment = a
            if(a == 1){
                this.depositSymbol = this.poolItem.offering.symbol
                this.minAmount = this.poolItem.minAlloc
                this.maxAmount = this.poolItem.maxAlloc
            }else if (a == 2){
                this.depositSymbol = 'ETH'
                this.minAmount = this.poolItem.minAllocERC20
                this.maxAmount = this.poolItem.maxAllocERC20
            }
        },
        closejoinOneTips(){
            this.joinOneTips = false
        },
        changeNum(e){
            if (e.target._value != '') {
                this.amount = e.target._value.replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d\d\d\d\d\d).*$/,'$1$2.$3')
            }
        },
        max(){
            // if (this.poolItem.offeringType == 1) {
            //     if (this.ERC20Balance > this.poolItem.maxAllocERC20) {
            //         this.amount = this.poolItem.maxAllocERC20
            //     } else {
            //         this.amount = this.ERC20Balance
            //     }
            //     return false
            // }
            if(this.poolItem.accessType==1){
                // this.amount = BigNumber(this.QuotaOfData).div(Math.pow(10,this.poolItem.offering.decimals)).toString();
                if (this.Payment == 1) {
                
                    if (this.maxBalanceof > this.poolItem.maxAlloc) {
                        this.amount = this.poolItem.maxAlloc

                    } else {
                        this.amount = this.maxBalanceof;

                    }
                } else if (this.Payment == 2) {
                    if (this.ERC20Balance > this.poolItem.maxAllocERC20) {
                        this.amount = this.poolItem.maxAllocERC20

                    } else {
                        this.amount = this.ERC20Balance
                    }
                }
            }else{
                if (this.Payment == 1) {
                
                    if (this.maxBalanceof > this.poolItem.maxAlloc) {
                        this.amount = this.poolItem.maxAlloc

                    } else {
                        this.amount = this.maxBalanceof;

                    }
                } else if (this.Payment == 2) {
                    if (this.ERC20Balance > this.poolItem.maxAllocERC20) {
                        this.amount = this.poolItem.maxAllocERC20

                    } else {
                        this.amount = this.ERC20Balance
                    }
                }
                
                
            }
        },
        tipsOkBtn(){
            this.tipsShow = false
        },
        tipsNoBtn(){
            this.$message({
                type: 'info',
                message: 'Cancelled'
            });    
            this.tipsShow = false
        },
        joinPool(){
            // this.showDialog = true;
            if (this.poolItem.accessType == 1 && this.QuotaOfData == 0) {
                return false
            }
            if (this.poolItem.offeringType == 1 && this.now/1000 < this.poolItem.distTime) {
                return false
            }
            if (this.poolItem.accessType == 1 && this.DepositOfData != 0) {
                this.joinOneTips = true
                return false
            }
            this.depositText = 'Confirm'
            if ((this.now/1000 >= this.poolItem.startTime && this.poolItem.accessType == 0) || ((this.now/1000 <= this.poolItem.distTime || this.now/1000 >= this.poolItem.startTime) && this.poolItem.accessType == 1)) {
                return
            }
            this.getUserAddr();
            var decimals = Math.pow(10,this.poolItem.offering.decimals);
            // if(this.poolItem.accessType==1){
            //     this.amount = BigNumber(this.QuotaOfData).div(decimals).toString();
            // }
            judgeNetwork().then(res => {
                if (res == 0) {
                    return false
                } else {
                    this.depositBtnShow = true
                    if(this.poolItem.offering.isMainType == false){
                        if (this.poolItem.offeringType == '1') {
                            this.showDialog = true;
                            return false
                        }
                        getBalanceOf(this.poolItem.offering.contract,this.userAddress).then(res => {
                            this.$nextTick(() => {
                                this.maxBalanceof = this.$BigNumber(res).div(Math.pow(10,this.poolItem.offering.decimals))
                                if (res == 0) {
                                    this.BalanceOfData = res
                                } else {
                                    // var balance = res.slice(0,-this.poolItem.offering.decimals)
                                    var balance = this.$BigNumber(res).div(Math.pow(10,this.poolItem.offering.decimals)).toString()
                                    if (balance.length > 18) {
                                        this.BalanceOfData = balance.slice(0,18) + '…';
                                    } else {
                                        this.BalanceOfData = balance.slice(0,18);
                                    }
                                }
                                this.showDialog = true;
                            });
                        },error => {
                            // //console.log(error);
                        });
                    }else{
                        this.showDialog = true;
                        this.$spaceWeb3.eth.getBalance(this.userAddress).then(res => {
                            this.$nextTick(() => {
                                this.BalanceOfData = res/decimals;
                                this.showDialog = true;
                            });
                        },error => {
                            // //console.log(error);
                        });
                    }
                }
            })
        },
        Approve(){
            if(typeof this.AllowanceData == 'number'){return false}
            this.getUserAddr();
            if(this.poolItem.minSwapLevel - (this.poolItem.TotalDeposit/this.poolItem.ratio)<=0 && this.poolItem.accessType==1 || !this.userAddress || this.QuotaOfData==0 && this.poolItem.accessType==1 || this.now >= this.poolItem.startTime*1000 && this.poolItem.accessType == 0 || this.now <= this.poolItem.distTime*1000 && this.poolItem.accessType == 1 || this.now <= this.poolItem.distTime*1000 && this.poolItem.accessType == 0 || this.now >= this.poolItem.startTime*1000 && this.poolItem.accessType == 1){
                return false
            }
            judgeNetwork().then(res => {
                if (res == 0) {
                    return false
                }
                this.disabledbtn = true
                setApprove(this.poolItem.offering.contract,this.poolItem.offerContract,this.userAddress).then(res => {
                    this.disabledbtn = false
                    this.AllowanceData = res
                    this.poolItem.offering.isMainType == true
                    this.$forceUpdate
                    this.$notify({ type: 'success', message: 'Approve Success' });
                },error => {
                    // //console.log(error)
                    this.disabledbtn = false
                    localStorage.setItem('0x339299',1)
                    this.$refs.vhead.connectMetamask()
                }).catch(function(err){
                    // //console.log(err)
                    this.$message({
                        message: 'not open',
                        type: 'warning'
                    });
                });
            })
        },
        async deposit(){
            if(!this.depositBtnShow ){
                return false
            }
            const BigNumber = require('bignumber.js');
            this.getUserAddr();
            if(this.amount == '' ){
                this.$message({
                    message: 'enter amount',
                    type: 'warning'
                });
                return false
            }
            if (Number(this.amount) > Number(this.maxAmount)  || Number(this.amount) < Number(this.minAmount) ) {
                this.$message({
                    message: 'Check amount',
                    type: 'warning'
                });
                return false
            }
            if (this.DepositOfData >= this.poolItem.maxAlloc ) {
                this.$message({
                    message: 'Check amount',
                    type: 'warning'
                });
                return false
            }
            if (this.DepositOfData.plus(this.amount) > this.poolItem.maxAlloc ) {
                this.$message({
                    message: 'Check amount',
                    type: 'warning'
                });
                return false
            }
            if (this.poolItem.accessType == 1 && Number(this.amount)>Number(this.poolItem.surplus) ) {
                this.$message({
                    message: 'Exceeds the investable amount left in private pool',
                    type: 'warning'
                });
                return false
            }
            if (this.poolItem.accessType == 1 && Number(this.amount)>Number(this.poolItem.maxAlloc) ) {
                this.$message({
                    message: 'Check amount',
                    type: 'warning'
                });
                return false
            }
            var QuotaOfData = new BigNumber(this.QuotaOfData).dividedBy(new BigNumber(Math.pow(10,this.poolItem.offering.decimals))).toString()
            if(this.poolItem.accessType==1 ){
                if(this.amount-0>QuotaOfData ){
                    this.$message({
                        message: 'Check amount',
                        type: 'warning'
                    });
                    return false;
                }
            }
            if(this.amount>Number(this.BalanceOfData) ){
                this.$message({
                    message: 'Check amount',
                    type: 'warning'
                });
                return false;
            }
            
                this.depositText = 'Confirming'
                this.depositBtnShow = false
                if(this.Payment == 1){
                    var amount = Web3.utils.toBN(new BigNumber(this.amount).multipliedBy(new BigNumber(Math.pow(10,this.poolItem.offering.decimals))))
                    // var amount = this.$BigNumber(this.amount).multipliedBy(Math.pow(10,18))
                } else if (this.Payment == 2) {
                    var amount = Web3.utils.toBN(new BigNumber(this.amount).multipliedBy(new BigNumber(Math.pow(10,18))))
                    // var amount = this.$BigNumber(this.amount).multipliedBy(Math.pow(10,18))
                }
                setDeposit(this.poolItem,amount,this.userAddress,this.Payment).then(res => {
                    this.depositBtnShow = true
                    this.depositText = 'Confirm'
                    window.location.reload();
                },error => {
                    // //console.log(error);
                    this.$message({
                    message: 'Check amount',
                    type: 'warning'
                });
                    this.depositBtnShow = true
                    this.depositText = 'Confirm'
                }).catch(error => {
                    // //console.log(error)
                    this.$message({
                    message: 'Check amount',
                    type: 'warning'
                });
                    this.depositBtnShow = true
                    this.depositText = 'Confirm'
                });
            
        },
        handdleHarvestBtn(){
            this.getUserAddr();
            judgeNetwork().then(res => {
                if (res == 0) {
                    return false
                }
                this.harvestShow = false
                setHarvest(this.poolItem,this.userAddress).then(res => {
                    this.harvestShow = true
                    window.location.reload()
                },error => {
                    this.harvestShow = true
                    this.$message({
                        type:'warning',
                        message: 'User cancel'
                    })
                    // //console.log(error);
                }); 
            })
            
        },
        EthSend(){
            this.getUserAddr();
            ethereum.request({
            method: 'eth_sendTransaction',
                params: [
                    {
                        from: this.userAddress,
                        to: this.starterAddress,
                        value: Web3.utils.toHex(this.amount * Math.pow(10,this.poolItem.offering.decimals)),
                    },
                ],
            }).then((txHash) => {
                this.$alert('please wait', 'please wait', {
                    confirmButtonText: '确定',
                    callback: action => {
                        window.location.reload();
                    }
                });
            }).catch((error) =>{
                // //console.log(error)
            }  );
        },
        copylnk(text){
            var oInput = document.createElement('input');
            var v = this.browser.version;
            if(v.ios == true){
                oInput.setAttribute('disabled','disabled')
            }
            oInput.value = text;
            document.body.appendChild(oInput);
            oInput.select();
            document.execCommand("Copy");
            oInput.className = 'oInput';
            oInput.style.display='none';
            alert('copy success');
        },
        getUserAddr(){
            if(!this.userAddress){
                this.userAddress = '0x0000000000000000000000000000000000000000';
            }else{
                this.userAddress =  this.userAddress;
            }
        },
    },
}
</script>